"use strict";

const _ = require("underscore");
const $ = require("jquery");
const AppHelper = require("../../../app-helper");
const Backbone = require("backbone");
const CustomCoversFormModel = require("./custom-covers-form.model");
const FormComponents = require("../components/index");
const Yup = require("yup");

// validation schema for the custom cover form
const validationSchema = Yup.object().shape({
	artwork: Yup.string().required("Please select how you will supply the customer's artwork"),
	artwork_file: Yup.string().when("artwork", {
		is: "Upload Artwork",
		then: Yup.string().required("Please provide a valid logo file (.png, .jpg, .jpeg, .ai, .eps)"),
	}),
	artwork_url: Yup.string().when("artwork", {
		is: "Provide URL",
		then: Yup.string()
			.url("Please provide a valid URL for the logo")
			.required("Please enter a URL for the logo"),
	}),
	company_name: Yup.string().required("Please enter a valid company name"),
	customer_first_name: Yup.string().required("Please enter the customer's first name"),
	customer_last_name: Yup.string().required("Pleae enter the customer's last name"),
	industry: Yup.string().required("Please select one of the industry options from the drop down"),
	rep_email: Yup.string()
		.email("Please enter a valid email address")
		.required("Please provide your email address"),
	rep_name: Yup.string().required("Please provide your name"),
	sales_order_number: Yup.string().required("Please provide a Navision SO number"),
	send_samples_to: Yup.string().required("Please select who the samples should be sent to"),
	skus: Yup.array()
		.min(1)
		.required("Please select one or more covers you would like to sample"),
});

module.exports = Backbone.View.extend({
	model: new CustomCoversFormModel(),
	events: {
		submit: "validateForm",
		"keyup [data-watch='input']": "handleChange",
		"change [data-watch]": "handleChange",
		"keypress label[tabindex]": "handleKeypress",
	},
	initialize: function() {
		this.createFormComponents();
		this.model.on("change:errors", this.render, this);
	},
	handleChange: function(ev) {
		this.model.set(ev.currentTarget.name, ev.currentTarget.value);
	},
	handleKeypress: function(ev) {
		if (ev.keyCode === 13 || ev.keyCode === 32) {
			ev.preventDefault();
			ev.currentTarget.click();
		}
	},
	validateForm: function(ev) {
		// prevent normal form submission
		ev.preventDefault();

		// validate form data before sending
		validationSchema
			.validate(this.model.attributes, { abortEarly: false })
			.then(this.validationSuccess.bind(this))
			.catch(this.validationErrors.bind(this));
	},
	validationErrors: function(err) {
		const errors = {};
		if (err.name === "ValidationError" && err.inner) {
			err.inner.reduce((errors, error) => {
				errors[error.path] = error.message;
				return errors;
			}, errors);
		}
		this.model.set("errors", errors);
	},
	validationSuccess: function() {
		// add wufoo hash to form
		this.$el.append(
			$("<input>")
				.attr({
					type: "hidden",
					name: "wufooHash",
				})
				.val("qodfgot0nh6zwx")
		);

		// combine model selected skus into a list for wufoo
		const skuList = _.clone(this.model.get("skus"))
			.reduce((list, item) => {
				list.push(item.value);
				return list;
			}, [])
			.join(", ");

		// add sku list to form
		this.$el.append(
			$("<input>")
				.attr({
					type: "hidden",
					name: "skus",
				})
				.val(skuList)
		);

		// disable button and show loading
		const $button = this.$el.find('button[type="submit"]');
		const $buttonText = $button.html();
		$button.attr("disabled", true).html("<span>Submitting...</span>");

		const formdata = window.FormData ? new FormData(this.el) : false;
		this.model.submitForm(formdata, this.el, {
			success: this.formSubmitSuccess.bind(this),
			error: this.formSubmitError.bind(this),
			complete: () => $button.removeAttr("disabled").html($buttonText),
		});
	},
	formSubmitError: function(jqXHR, textStatus, error) {
		console.error({
			jqXHR,
			textStatus,
			error,
		});

		$(document)
			.find(".page-wrapper")
			.prepend(
				$("<div>")
					.addClass("error-msg server-msg")
					.html(error)
			);
		setTimeout(() => $(".server-msg").remove(), 5000);
	},
	formSubmitSuccess: function(data) {
		console.log({ data });
		const type = data.messageType || "error";
		$(document)
			.find(".page-wrapper")
			.prepend(
				$("<div>")
					.addClass(type + "-msg server-msg")
					.html(data.message)
			);

		const timeout = data.messageType === "success" ? 2000 : 5000;
		setTimeout(() => $(".server-msg").remove(), timeout);

		// reset form
		if (data.messageType === "success") {
			this.model.clear().set(this.model.defaults);
			this.$el.find("input[type='text']").val("");
			this.$el.find("input[type='radio']").removeProp("checked");
			this.$el.find("select").val("");
			this.$el.find("textarea").val("");
			this.$el.find(".cover-card").removeClass("active");
		}
	},
	createFormComponents: function() {
		this.formElements = AppHelper.methods.createComponents({
			$el: this.$el,
			errorMsg: "No form component found for",
			dataAttr: "data-formcomp",
			components: FormComponents,
			model: this.model,
		});
	},
	render: function() {
		this.$el.find(".error-msg").remove();
		this.renderFormErrors();
	},
	renderFormErrors: function() {
		const errors = this.model.get("errors");

		// early return if there's no errors to display
		if (_.size(errors) === 0) {
			return false;
		}

		// loop errors and render them to DOM
		_.each(
			errors,
			(message, key) => {
				this.$el
					.find(`[name^="${key}"]`)
					.parents(".field")
					.find(".label-text")
					.after(
						$("<div>")
							.addClass("error-msg")
							.text(message)
					);
			},
			this
		);
	},
});
