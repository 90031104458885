"use strict";

const _ = require("underscore");
const $ = require("jquery");

// define AppHelper onto the window object to be used in the other files
const AppHelper = {};

AppHelper.settings = {
	deviceSizes: {
		smMobile: 321,
		mobile: 481,
		smTablet: 601,
		tablet: 769,
		smDesktop: 961,
		desktop: 1201,
	},
};

AppHelper.methods = {
	spinnerPreloaded: false,
	spinnerImage: "/pub/images/base/spinner.gif",
	buttonText: {},

	/**
	 * Stops the default action of the event and prevents it from bubbling
	 *
	 * @param      {object}  ev      Event to stop
	 */
	stopDefault: function(ev) {
		if (ev === null || typeof ev === "undefined" || !ev.type) {
			return false;
		}

		ev.preventDefault();
		ev.stopPropagation();
	},

	/**
	 * Gets the element as jquery.
	 *
	 * @param      object  el      DOM object
	 * @return     object  The element as jquery object.
	 */
	getElementAsJquery: function(el) {
		if (!(el instanceof $)) {
			return $(el);
		}
		return el;
	},

	/**
	 * Gets the url search/query the browser window.
	 *
	 * @return     {string}  The url search.
	 */
	getUrlHash: function() {
		return window.location.search.substr(1) || "";
	},

	/**
	 * Gets the url search/query the browser window.
	 *
	 * @return     {string}  The url search.
	 */
	getUrlSearch: function() {
		return window.location.search.substr(1) || "";
	},

	/**
	 * Gets the domain.from the url
	 *
	 * @return     {string}  The domain.
	 */
	getDomain: function() {
		return (
			window.location.protocol +
			"//" +
			window.location.hostname +
			(window.location.port ? ":" + window.location.port : "")
		);
	},

	/**
	 * Determines if the current environment is admin.
	 *
	 * @return     {boolean}  True if admin environment, False otherwise.
	 */
	isAdminEnv: function() {
		return window.location.href.substr(this.getDomain().length + 1, 5) === AppHelper.settings.adminRoute;
	},

	/**
	 * Gets the url base.
	 *
	 * @return     {string}  The url base.
	 */
	getUrlBase: function() {
		return this.isAdminEnv() ? this.getDomain() + "/" + AppHelper.settings.adminRoute : this.getDomain();
	},

	/**
	 * Scroll the user to an elements positions
	 *
	 * @param      {object}    $item     The item to scroll to
	 * @param      {number}    duration  The duration of the animation
	 * @param      {number}    offset    Further offset from the item
	 * @param      {function}  func      Function to run once the animation is complete
	 */
	scrollToPosition: function($item, duration, offset, func) {
		var scrollto = 0;
		func = func || null;
		offset = offset || 10;

		if (!isNaN($item)) {
			scrollto = $item;
		} else if ($item !== null && typeof $item !== "undefined") {
			scrollto = $item.offset().top;
		}

		$("html, body").animate(
			{
				scrollTop: scrollto ? scrollto - offset + "px" : "0px",
			},
			duration,
			func
		);
	},

	/**
	 * Perform an animation on an element
	 *
	 * @param      {object}  item     The item to animate
	 * @param      {object}  options  The options for the animation
	 */
	performAnimation: function(item, options) {
		options.callback = typeof options.callback === "function" ? options.callback : null;
		item.stop(true, false).animate(options.animation, options.speed, options.callback);
	},

	/**
	 * Check if a function exists. Run on the provided element if it does
	 *
	 * @param      {mixed}     item    The item to run the function on
	 * @param      {function}  fn      The function to check and execute
	 * @return     {string}    The result of the provided function
	 */
	ifSetRun: function(item, fn) {
		if (typeof fn !== "function") {
			return "";
		}
		return typeof item !== "undefined" ? fn(item) : "";
	},

	/**
	 * Strips the leading slash from the URL
	 *
	 * @param      {string}  url     Url to evaluate
	 * @return     {string}  Cleaned url
	 */
	setBaseURL: function(url) {
		if (typeof url === "undefined") {
			return false;
		}
		return url.substr(url.length - 1, url.length) !== "/" ? url + "/" : url;
	},

	/**
	 * Sets the cookie by name.
	 *
	 * @param      {string}  name    The name
	 * @param      {string}  value   The value
	 * @param      {number}  days    The days
	 */
	setCookieByName: function(name, value, days) {
		var expires = "";

		if (days) {
			var date = new Date();
			date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
			expires = "; expires=" + date.toUTCString();
		}

		document.cookie = name + "=" + value + expires + "; path=/";
	},

	/**
	 * Gets the cookie by name.
	 *
	 * @param      {string}  name    The name
	 * @return     {string}  Cookie information.
	 */
	getCookieByName: function(name) {
		var cname = name + "=";
		var decodedCookie = decodeURIComponent(document.cookie);
		var ca = decodedCookie.split(";");

		for (var i = 0; i < ca.length; i++) {
			var c = ca[i];
			while (c.charAt(0) === " ") {
				c = c.substring(1);
			}
			if (c.indexOf(cname) === 0) {
				return c.substring(cname.length, c.length);
			}
		}

		return "";
	},

	/**
	 * Update cookie by name
	 *
	 * @param      {string}  name    The name
	 * @param      {string}  value   The value
	 * @param      {number}  days    The days
	 */
	updateCookieByName: function(name, value, days) {
		this.deleteCookieByName(name);
		this.setCookieByName(name, value, days);
	},

	/**
	 * Delete cookie by name
	 *
	 * @param      {string}  name    The name
	 */
	deleteCookieByName: function(name) {
		document.cookie = name + "=; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/";
	},

	/**
	 * Adds a spinner.
	 *
	 * @param      {element}  el      Element to add the spinner to
	 */
	addSpinner: function(el) {
		var $el = this.getElementAsJquery(el);
		var width = $el.width();
		this.buttonText[$el] = $el.html();
		$el.addClass("spinner-holder")
			.attr("disabled", true)
			.html("");
		$el.append(
			$("<img>")
				.addClass("spinner")
				.attr("src", this.spinnerImage),
			$("<div>")
				.width(width)
				.html("&nbsp;")
		);
	},

	/**
	 * Removes a spinner.
	 *
	 * @param      {element}  el      Element to remove the spinner from
	 */
	removeSpinner: function(el) {
		var $el = this.getElementAsJquery(el);
		$el.html(this.buttonText[$el])
			.removeClass("spinner-holder")
			.removeAttr("disabled");
		this.buttonText = _.without(this.buttonText, $el);
	},

	/**
	 * Get the pdf filename
	 *
	 * @param      {string}  file      Preview file image
	 */
	getPdfFilename: function(pdfFile) {
		var file = pdfFile || "";
		if (file === "") {
			return "";
		}

		var paths = file.split("/");
		return paths.pop();
	},

	/**
	 * Get the preview image for a pdf
	 *
	 * @param      {string}  file      PDF file to get preview image for
	 */
	getPdfPreviewImage: function(pdfFile) {
		var file = pdfFile || "";
		if (file === "") {
			return "";
		}

		var filepathNoExt = file.split(".");
		filepathNoExt.pop();
		return filepathNoExt + ".jpg";
	},

	/**
	 * Get the formatted date
	 *
	 * @param      {string}  date      Date to be formatted
	 */
	formatDate: function(datetime) {
		datetime = datetime || "";
		if (datetime === "") {
			return false;
		}

		var dateParts = $.trim(datetime)
			.split(" ")[0]
			.split("-");

		var date = [];
		date.push(this.getZeroPadNum($.trim(dateParts[1]), 2));
		date.push(this.getZeroPadNum($.trim(dateParts[2]), 2));
		date.push($.trim(dateParts[0]));

		var time = $.trim(datetime)
			.split(" ")[1]
			.split(":")
			.splice(0, 2); // remove seconds
		var ampm = time[0] < 12 ? "AM" : "PM";
		time[0] = ((parseInt(time[0]) + 11) % 12) + 1;

		return this.isAdminEnv() ? date.join("/") + " " + time.join(":") + " " + ampm : date.join("/");
	},

	/**
	 * Get a digit zero padded
	 *
	 * @param      {number}  num      Number to be formatted
	 * @param      {number}  size     Length of number to be returned
	 */
	getZeroPadNum: function(num, size) {
		size = size || 2;
		return ("0000000000" + num).slice(-size);
	},

	/**
	 * Create new components based on data attribute
	 */
	createComponents: function(settings) {
		const createdComponents = [];

		_.each(settings.$el.find(`[${settings.dataAttr}]`), function(el) {
			const comp = $(el).attr(settings.dataAttr);
			let Component = settings.components;

			// find component to instantiate
			_.each(comp.split("."), function(item) {
				if ([item] !== undefined) {
					Component = Component[item];
				} else {
					throw new Error(settings.errorMsg + " " + item);
				}
			});

			// instantiate components with the current element and model
			// checking if a model is provided before adding it
			const newCompObj = Object.assign({}, { el: el }, settings.model && { model: settings.model });
			createdComponents.push(new Component(newCompObj));
		});

		return createdComponents;
	},
};

module.exports = AppHelper;
