"use strict";

const Backbone = require("backbone");
const ProvideLogoModel = require("./provide-logo.model");

module.exports = Backbone.View.extend({
	model: this.model || new ProvideLogoModel(),
	events: {
		"change input": "handleChange",
		"keyup input[name='artwork_url']": "handleChange",
	},
	initialize: function() {
		this.model.on(
			{
				"change:artwork": this.handleModelUpdate,
				"change:artwork_file": this.handleModelUpdate,
				"change:artwork_url": this.handleModelUpdate,
			},
			this
		);
	},
	getFieldName: function(field) {
		return field
			? field
					.toLowerCase()
					.split(" ")
					.join("-")
			: "";
	},
	handleModelUpdate: function() {
		const field = this.getFieldName(this.model.get("artwork"));

		// clear out data for the option that is not set
		if (field !== "upload-artwork") {
			this.model.set("artwork_file", "");
		} else if (field !== "provide-url") {
			this.model.set("artwork_url", "");
		}

		// render out component to DOM
		this.render();
	},
	handleChange: function(ev) {
		const name = ev.currentTarget.name;
		let value = ev.currentTarget.value;

		// set the filename without the path
		const val = name === "artwork_file" && value ? value.split(/\/|\\/).pop() : value;
		this.model.set(name, val);
		this.render();
	},
	render: function() {
		const field = this.getFieldName(this.model.get("artwork"));
		const $item = field ? this.$el.find(`.artwork.${field}`) : null;
		const filename = this.model.get("artwork_file");

		// hide visible fields and reset value
		this.$el
			.find(".artwork")
			.not($item)
			.addClass("hidden")
			.find("input")
			.val("");

		// display selected field
		if ($item) {
			$item.removeClass("hidden");
		}

		// display filename
		if (field === "upload-artwork") {
			this.$el.find("#artwork_filename").html(filename);
		}

		return this;
	},
});
