"use strict";

const Backbone = require("backbone");

module.exports = Backbone.Model.extend({
	defaults: {
		artwork: "",
		"artwork-file": "",
		"artwork-url": "",
	},
});
