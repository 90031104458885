"use strict";

const $ = require("jquery");
const _ = require("underscore");
const Backbone = require("backbone");
const CustomCoversCardModel = require("./custom-cover-cards.model");

module.exports = Backbone.View.extend({
	model: this.model || new CustomCoversCardModel(),
	events: {
		"click .radio-item>label": "handleChange",
	},
	initalize: function() {},
	handleChange: function(ev) {
		// stop double clicks from happening
		ev.preventDefault();

		const $radio = $(ev.currentTarget).find('input[type="radio"]');
		const type = $radio.attr("name").split("_")[1];

		let previousValue = null;
		const selectedSkus = _.clone(this.model.get("skus"));
		const index = selectedSkus.findIndex(cover => cover.type === type);

		// remove previous selection
		if (index > -1) {
			previousValue = selectedSkus[index].value;
			selectedSkus.splice(index, 1);
		}

		if (previousValue !== $radio.val()) {
			const update = { type, value: $radio.val() };
			selectedSkus.push(update);
		}
		this.model.set("skus", selectedSkus);

		if (previousValue === $radio.val()) {
			$radio.prop("checked", false);
			$(ev.currentTarget)
				.parents(".cover-card")
				.removeClass("active");
		} else {
			$radio.prop("checked", true);
			$(ev.currentTarget)
				.parents(".cover-card")
				.addClass("active");
		}
	},
	render: function() {},
});
