"use strict";

const $ = require("jquery");
const Backbone = require("backbone");

module.exports = Backbone.Model.extend({
	timeout: 25000, // allow ajax to process for 25 seconds
	defaults: {
		artwork: "",
		artwork_file: "",
		artwork_url: "",
		company_name: "",
		customer_first_name: "",
		customer_last_name: "",
		errors: {},
		industry: "",
		rep_email: "",
		rep_name: "",
		sales_order_number: "",
		send_samples_to: "",
		skus: [],
		special_instructions: "",
	},
	submitForm: function(data, form, response) {
		$.ajax({
			url: "/customcovers/ajax/submit-post",
			method: "post",
			data: data ? data : form.serialize(),
			dataType: "json",
			cache: false,
			contentType: false,
			processData: false,
			timeout: this.timeout,
			success: response.success,
			error: response.error,
			complete: response.complete || null,
		});
	},
});
